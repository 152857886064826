import { jsx } from "react/jsx-runtime";
import { createState } from "@tecnobit-srl/state";
import { createContext, Component, createElement, isValidElement, useMemo, useCallback, Suspense } from "react";
import { merge } from "lodash-es";
import { GenericError } from "@tecnobit-srl/ui-generic-error";
const ErrorBoundaryContext = createContext(null);
const initialState = {
  didCatch: false,
  error: null
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }
  static getDerivedStateFromError(error) {
    return {
      didCatch: true,
      error
    };
  }
  resetErrorBoundary() {
    const {
      error
    } = this.state;
    if (error !== null) {
      var _this$props$onReset, _this$props;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      (_this$props$onReset = (_this$props = this.props).onReset) === null || _this$props$onReset === void 0 ? void 0 : _this$props$onReset.call(_this$props, {
        args,
        reason: "imperative-api"
      });
      this.setState(initialState);
    }
  }
  componentDidCatch(error, info) {
    var _this$props$onError, _this$props2;
    (_this$props$onError = (_this$props2 = this.props).onError) === null || _this$props$onError === void 0 ? void 0 : _this$props$onError.call(_this$props2, error, info);
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      didCatch
    } = this.state;
    const {
      resetKeys
    } = this.props;
    if (didCatch && prevState.error !== null && hasArrayChanged(prevProps.resetKeys, resetKeys)) {
      var _this$props$onReset2, _this$props3;
      (_this$props$onReset2 = (_this$props3 = this.props).onReset) === null || _this$props$onReset2 === void 0 ? void 0 : _this$props$onReset2.call(_this$props3, {
        next: resetKeys,
        prev: prevProps.resetKeys,
        reason: "keys"
      });
      this.setState(initialState);
    }
  }
  render() {
    const {
      children,
      fallbackRender,
      FallbackComponent,
      fallback
    } = this.props;
    const {
      didCatch,
      error
    } = this.state;
    let childToRender = children;
    if (didCatch) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary
      };
      if (typeof fallbackRender === "function") {
        childToRender = fallbackRender(props);
      } else if (FallbackComponent) {
        childToRender = createElement(FallbackComponent, props);
      } else if (fallback === null || isValidElement(fallback)) {
        childToRender = fallback;
      } else {
        throw error;
      }
    }
    return createElement(ErrorBoundaryContext.Provider, {
      value: {
        didCatch,
        error,
        resetErrorBoundary: this.resetErrorBoundary
      }
    }, childToRender);
  }
}
function hasArrayChanged() {
  let a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
  let b = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
  return a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));
}
function createComponentStore(name, defaultLoadingComponent) {
  const { ContextProvider: ComponentStoreProvider, useStore } = createState(
    name,
    (set) => ({
      components: {},
      addComponent: (id, component) => {
        set((state) => {
          return {
            ...state,
            components: {
              ...state.components,
              [id]: component
            }
          };
        });
      }
    }),
    (state, props) => ({
      ...state,
      ...props,
      components: props.components ? merge(state.components, props.components) : state.components
    })
  );
  const useAddComponent = () => {
    return useStore((state) => state.addComponent);
  };
  const useComponents = () => {
    return useStore((state) => state.components);
  };
  function Component2({ id, p }) {
    const components = useComponents();
    const currentComponent = useMemo(() => components[id], [components, id]);
    const LazyComponent = useMemo(
      () => currentComponent == null ? void 0 : currentComponent.component,
      [currentComponent]
    );
    const renderError = useCallback(
      ({ error }) => {
        return /* @__PURE__ */ jsx(
          GenericError,
          {
            message: void 0
          }
        );
      },
      [id]
    );
    if (!currentComponent || !LazyComponent) {
      return /* @__PURE__ */ jsx(
        GenericError,
        {
          message: void 0
        }
      );
    }
    return /* @__PURE__ */ jsx(ErrorBoundary, { fallbackRender: renderError, children: /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: currentComponent.loadingComponent ?? defaultLoadingComponent ?? null,
        children: /* @__PURE__ */ jsx(LazyComponent, { ref: null, ...p })
      }
    ) });
  }
  return {
    ComponentStoreProvider,
    useAddComponent,
    useComponents,
    Component: Component2
  };
}
export {
  createComponentStore
};
